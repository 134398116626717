import { createSlice } from '@reduxjs/toolkit';
//
import { dispatch } from '../store';
import axios from '../../utils/axios';

const initialState = {
  itemsResource: [],
  // centerMap: null,
  currentResource: null,
  selectResource: null,
  newResource: false,
  itemsAttribute: [],
  allsAttribute: [],
  // centerMap: null,
  currentAttribute: null,
  selectAttribute: null,
  newAttribute: false
};

const slice = createSlice({
  name: 'resources',
  initialState,
  reducers: {
    setItemsResourceData(state, action) {
      state.itemsResource = action.payload;
    },
    addItemsResourceData(state, action) {
      state.itemsResource.push(action.payload);
    },
    setSelectResourceData(state, action) {
      state.selectResource = action.payload;
      console.log(state.selectResource);
    },
    setNewResource(state, action) {
      state.newResource = action.payload;
    },
    setItemsAttributeData(state, action) {
      state.itemsAttribute = action.payload;
    },

    setAllsAttributeData(state, action) {
      state.allsAttribute = action.payload;
    },

    addItemsAttributeData(state, action) {
      state.itemsAttribute.push(action.payload);
    },
    setSelectAttributeData(state, action) {
      state.selectAttribute = action.payload;
    },
    setNewAttribute(state, action) {
      state.newAttribute = action.payload;
    }      

  }
});

// Reducer
export default slice.reducer;

// Actions
export const {
  setItemsResourceData,
  addItemsResourceData,
  setSelectResourceData,
  setNewResource,
  setItemsAttributeData,
  setAllsAttributeData,
  addItemsAttributeData,
  setSelectAttributeData,
  setNewAttribute


} = slice.actions;

export const getItemsResource = async () => {
  // -dispatch(setItemsZonaData([]));
  await axios.get(`api-sys/resources`)
    .then(res => {
      if (res.status === 200) {
        dispatch(setItemsResourceData(res.data));
        dispatch(setSelectResourceData({}));
      }
    })
}

export const getSelectResource = async (id) => {
  // -dispatch(setItemsZonaData([]));
  await axios.get(`api-sys/resources/${id}`)
    .then(res => {
      if (res.status === 200) {
        dispatch(setSelectResourceData(res.data[0]));
      }
    })
}

export const setSelectResource = async (obj) => {
    dispatch(setSelectResourceData(obj));
    getSelectResource(obj.codeResource);
}

export const saveSelectResource = async (item) => {
  await axios.post(`api-sys/resources`, item)
  .then(res => {
    if (res.status === 200) {
      getItemsResource();
    }
  })
}

export const delSelectResource = async (item) => {
  await axios.delete(`api-sys/resources/${item.codeResource}`)
  .then(res => {
    if (res.status === 200) {
      getItemsResource();
    }
  })
}
// -------------------------------------------------
export const getItemsAttribute = async () => {
  // -dispatch(setItemsZonaData([]));
  await axios.get(`api-sys/attributes`)
    .then(res => {
      if (res.status === 200) {
        dispatch(setItemsAttributeData(res.data));
        dispatch(setSelectAttributeData({}));
      }
    })
}

export const getAllsAttribute = async (id) => {
  // -dispatch(setItemsZonaData([]));
  await axios.get(`api-sys//resources/${id}/attributes`)
    .then(res => {
      if (res.status === 200) {
        dispatch(setAllsAttributeData(res.data));
      }
    })
}

export const getSelectAttribute = async (id) => {
  // -dispatch(setItemsZonaData([]));
  await axios.get(`api-sys/attributes/${id}`)
    .then(res => {
      if (res.status === 200) {
        setSelectAttributeData(res.data[0]);
      }
    })
}

export const setSelectAttribute = async (obj) => {
    setSelectAttributeData(obj);
}

export const saveSelectAttribute = async (item) => {
  await axios.post(`api-sys/attributes`, item)
  .then(res => {
    if (res.status === 200) {
      getItemsAttribute();
    }
  })
}
export const saveLinkAttribute = async (item) => {
  await axios.post(`api-sys/resources/attribute`, item)
  .then(res => {
    if (res.status === 200) {
      ; // getItemsAttribute();
    }
  })
}

export const delSelectAttribute = async (item) => {
  await axios.delete(`api-sys/attributes/${item.codeAttribute}`)
  .then(res => {
    if (res.status === 200) {
      getItemsAttribute();
    }
  })
}
export const delLinkAttribute = async (idR, idA) => {
  await axios.delete(`api-sys/resources/${idR}/attribute/${idA}`)
  .then(res => {
    if (res.status === 200) {
      getSelectResource(idR);
    }
  })
}

// -- end ----------------------------------------
