import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
// utils
import axios from '../../utils/axios';
import i18n from '../../locales/i18n';
//
import { dispatch } from '../store';
import { showConfirmationModal } from './confirmation';

const initialState = {
    isLoading: false,
    exeServices: [],
    dicStationsAll: [],
};

const slice = createSlice({
    name: 'exeMonitoring',
    initialState,
    reducers: {
        // START LOADING
        setLoading(state, action) {
            state.isLoading = action.payload;
        },
        setExeServices(state, action) {
            state.exeServices = action.payload;
        },
        setDicStationsAll(state, action) {
            state.dicStationsAll = action.payload;
        }
    }
});

// Reducer
export default slice.reducer;

// Actions
export const {
    setLoading,
    setExeServices,
    setDicStationsAll
} = slice.actions;

// --------НДІ Станції
export const getDicStationsAll = async () => {
    await axios.get(`/api-nsi/api/v1/DataNsi/ThreeColumns?tableName=views.V_Stations&fieldOutOne=display_info&fieldOutTwo=code_station&fieldOutThree=code_adm&all=true`)  
      .then(res => {
        if(res.status === 200) {
          const newData = [];        
          res.data.forEach(item => {            
            const newItem = {'value': item.field2, 'title': item.field1, 'code': item.field3};
            if (!newData.includes(newItem)) {
              newData.push(newItem)
            } 
            else {      
              throw new Error(res.statusText || res.status);          
            }                  
          });  
          dispatch(setDicStationsAll(newData));
        }
      })
      .catch((error) => {    
        toast.warn(`${i18n.t('service.errors.error')}: ${error}`, {autoClose: false, hideProgressBar: false, closeOnClick: true}); 
      });        
  };

  // --------Перелік послуг для груп вагорів у контракті
  // 
  export const getExeServicesByContract = async (idContract /* 148045 */) => {
    dispatch(setLoading(true));
    const data =`"SELECT ` +
    ` gr.id_route as idRoute  ` +
    ` , routes.name_route as tidRoute  ` +
    ` , actions.id_action as idAction ` +   
    ` , CONCAT(dic_actions.name_full,': ', stations.display_info) as nameActionOnStation ` +
    ` , services.id_service as idService  ` +
    ` , gr.id_group as idGroup ` +
    ` , CONCAT(services.id_service, '_', gr.id_group) as idServiceWithGroup ` +
    ` , dic_services.name_full as nameService  ` +    
    ` , services.is_key as isKey ` +    
    ` , gr.name_order as nameGroup ` +
    ` , gr.group_weight as weightGroup ` +
    ` , gr.count_vag as countVagGroup  ` +   
    ` , CONCAT(DATE_FORMAT(services.date_plan_beg, '%d.%m.%Y'), ' - ', DATE_FORMAT(services.date_plan_end, '%d.%m.%Y')) as datePeriodPlan  ` + 
    ` , GROUP_CONCAT(dic_pidpr.name_short SEPARATOR ', ') AS listExecutorsPlan ` +
    ` FROM IC.Slc_Services services  ` +
    ` RIGHT JOIN ` +
    ` (SELECT distinct m.id_slc, s.id_group  ` +
      ` FROM IC.Slc_Services s ` + 
      ` LEFT JOIN IC.Slc_Main m on m.id_slc  = s.id_slc  ` +
      ` WHERE s.id_group in (SELECT id_group FROM IC.Cargo_Group WHERE id_contract=${idContract} and id_route is not null) ` +
      ` AND m.type_slc = 1) slc_groups ` + 
      ` on (services.id_group = slc_groups.id_group OR services.id_group is null) AND services.id_slc = slc_groups.id_slc  ` +
      ` LEFT JOIN IC.Cargo_Group gr on gr.id_group = slc_groups.id_group ` +
      ` LEFT JOIN IC.Cargo_Route routes on routes.id_route = gr.id_route ` +
      ` LEFT JOIN IC.Slc_Actions actions on services.id_action = actions.id_action ` +
      ` LEFT JOIN nsi_ekbs.Dic_Actions_Form dic_actions on actions.code_action = dic_actions.code_action ` +
      ` LEFT JOIN views.V_Stations stations on stations.code_station = actions.action_station  ` +
      ` LEFT JOIN nsi_ekbs.Dic_Pidpr_Services dic_services on services.code_service = dic_services.code_service  ` +
      ` LEFT JOIN IC.Slc_Services_Executors executors on executors.id_service = services.id_service and executors.id_group = gr.id_group ` +
      ` LEFT JOIN pidpr.Ekbs_Pidpr_Agreements agreements on agreements.id_agree = executors.id_agreement ` +
      ` LEFT JOIN views.V_Ekbs_Pidpr_All dic_pidpr on dic_pidpr.id_vsp = agreements.id_saller  ` +

      ` GROUP BY gr.id_route, routes.name_route, actions.id_action, CONCAT(dic_actions.name_full,': ', stations.display_info),  services.id_service, gr.id_group,` + 
      ` CONCAT(services.id_service, '_', gr.id_group), dic_services.name_full, services.is_key, gr.name_order, gr.group_weight, gr.count_vag,  ` +
      ` CONCAT(DATE_FORMAT(services.date_plan_beg, '%d.%m.%Y'), ' - ', DATE_FORMAT(services.date_plan_end, '%d.%m.%Y'))  ` +

      ` order by services.id_slc, services.date_plan_beg, services.id_service, gr.id_group"`;

    return axios.post(`/api-vantazh/NsiData`, data, { headers: {  'Content-Type': 'text/json' } } ) 
    .then(res => {
        if(res.status === 200) {  
            console.log("getExeServicesByContract", res.data);    
            dispatch(setExeServices(res.data));
        }          
    })
    .catch((error) => {      
        // console.log(error);
      toast.error(`${i18n.t('service.errors.error')}: ${error}`, {autoClose: false, hideProgressBar: false, closeOnClick: true}); 
    })
    .finally(() => dispatch(setLoading(false)) );
  };