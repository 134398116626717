import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
// import i18n from '../../locales/i18n';
// actions
import { showConfirmationModal } from './confirmation';
import { loaderEnd, loaderStart } from './loader';

// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

const initialState = {
  availableResources: [],
  myResources: [],
  resourceDetails: null,
  selectedAttr: null,
  selectedResourceId: null,
  nsiSelectData: [],
  nsiAutocompleteData: [],
};

const slice = createSlice({
  name: 'enterpriseResources',
  initialState,
  reducers: {
    setAvailableResources: (state, action) => ({
      ...state,
      availableResources: action.payload,
    }),
    setMyResources: (state, action) => ({
      ...state,
      myResources: action.payload,
    }),
    setResourceDetails: (state, action) => ({
      ...state,
      resourceDetails: action.payload,
    }),
    setSelectedAttr: (state, action) => ({
      ...state,
      selectedAttr: action.payload,
    }),
    setSelectedResourceId: (state, action) => ({
      ...state,
      selectedResourceId: action.payload,
    }),
    setNsiSelectData: (state, action) => ({
      ...state,
      nsiSelectData: {
        ...state.nsiSelectData,
        [action.payload.nameProp]: action.payload.data,
      },
    }),
    setNsiAutocompleteData: (state, action) => ({
      ...state,
      nsiAutocompleteData: {
        ...state.nsiAutocompleteData,
        [action.payload.nameProp]: action.payload.data,
      },
    }),
  },
});

export default slice.reducer;

export const {
  setAvailableResources,
  setMyResources,
  setResourceDetails,
  setSelectedAttr,
  setNsiSelectData,
  setNsiAutocompleteData,
  setSelectedResourceId,
} = slice.actions;

export const getAvailableResources = async () => {
  // loaderStart();
  await axios
    .get(`/api-sys/resources`)
    .then((res) => {
      if (res.status === 200) {
        dispatch(setAvailableResources(res.data));
      }
    })
    .catch((err) => {
      console.log(err);
    });
  // loaderEnd();
};

export const getMyResources = async () => {
  loaderStart();
  await axios
    .get(`/api-sys/resources/pidpr`)
    .then((res) => {
      if (res.status === 200) {
        dispatch(setMyResources(res.data));
      }
    })
    .catch((err) => {
      console.log(err);
    });
  loaderEnd();
};

export const saveResource = async (data, hideForm) => {
  await axios
    .post(`/api-sys/resources/pidpr`, data)
    .then((res) => {
      if (res.status === 200) {
        getMyResources();
        toast.success('Ресурс успішно додано.');
        hideForm();
      }
    })
    .catch((err) => {
      console.log(err);
    });
};

export const deleteResource = (id) => {
  showConfirmationModal({
    isOpen: true,
    title: 'Підтвердження видалення',
    content: `Ви дійсно хочете видалити ресурс?`,
    onSubmit: () => submitDelete(id),
    onCancel: () => showConfirmationModal({ isOpen: false }),
  });
};

export const submitDelete = async (id) => {
  await axios.delete(`/api-sys/resources/pidpr/${id}`).then((res) => {
    if (res.status === 200) {
      getMyResources();
      toast.success('Ресурс видалено.');
      showConfirmationModal({ isOpen: false });
    }
  });
};

export const getResourceDetails = async (id) => {
  await axios.get(`/api-sys/resources/pidpr/${id}`).then((res) => {
    if (res.status === 200) {
      dispatch(setResourceDetails(res.data[0]));
      dispatch(setSelectedResourceId(id));
    }
  });
};

export const selectAttr = (attr) => {
  if (attr?.attribute.typeAttr === 'AUTOCOMPLETE') {
    const existOption = [
      {
        value: attr.attribute.value,
        title: attr.attribute.nameValue,
      },
    ];
    dispatch(setNsiAutocompleteData({ data: existOption, nameProp: attr.attribute.nameProp }));
  }
  dispatch(setSelectedAttr(attr));
};

export const getSelectNsiData = async (params) => {
  const { nameTable, nameField, keyField, nameProp } = params;
  await axios
    .get(`/api-nsi/api/DataNsi/GetAllValues?table=${nameTable}&titleOut=${nameField}&valueOut=${keyField}&all=true`)
    .then((res) => {
      if (res.status === 200) {
        dispatch(setNsiSelectData({ data: res.data, nameProp }));
      }
    })
    .catch((error) => {
      console.log(error);
    });
};

export const getNsiAutocompleteData = async (select, searchValue) => {
  const { nameTable, nameField, keyField, nameProp } = select;
  const searchField = Number(searchValue) ? keyField : nameField;
  await axios
    .get(
      `/api-nsi/api/DataNsi/GetAllValues?table=${nameTable}&titleOut=${nameField}&valueOut=${keyField}&value=${searchValue}&searchField=${searchField}&all=false&limit=2000`
    )
    .then((res) => {
      if (res.status === 200) {
        dispatch(setNsiAutocompleteData({ data: res.data, nameProp }));
      }
    })
    .catch((error) => {
      dispatch(slice.actions.hasError(error));
    });
};

export const saveAttrDetails = async (id, data) => {
  await axios.post(`/api-sys/resources/pidpr/${id}`, [data]).then((res) => {
    if (res.status === 200) {
      getResourceDetails(id);
      selectAttr(null);
    }
  });
};
